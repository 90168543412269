import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.AUTH_SERVER_PREFIX}/oauth`
const endpoints = {
    loadGlobalState: () => `${RESOURCE_ENDPOINT}/load-global-state`,
    loadAuthState: () => `${RESOURCE_ENDPOINT}/load-auth-state`,
    loadUserScopes: () => `${RESOURCE_ENDPOINT}/load-user-scopes`,
    loadProfileState: () => `${RESOURCE_ENDPOINT}/load-profile-state`,
    login: () => `${RESOURCE_ENDPOINT}/login`,
    register: () => `${RESOURCE_ENDPOINT}/register`,
    verifyUserEmail: () => `${RESOURCE_ENDPOINT}/verify-email`,
    forgotPassword: () => `${RESOURCE_ENDPOINT}/forgot-password`,
    resetPassword: () => `${RESOURCE_ENDPOINT}/reset-password`,
    changePassword: () => `${RESOURCE_ENDPOINT}/change-password`,
    updateAccount: () => `${RESOURCE_ENDPOINT}/update-account`,
    logout: () => `${RESOURCE_ENDPOINT}/logout`,
    refreshToken: () => `${RESOURCE_ENDPOINT}/token/refresh`,
    invitePeople: () => `${RESOURCE_ENDPOINT}/invite-people`,
    validateIdTokenAndGenerateAccessToken: () => `${RESOURCE_ENDPOINT}/validate-id-token-and-generate-access-token`,
}

export default class OauthApi {

    public loadGlobalState = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.loadGlobalState();
        return HttpService.post(url, payload, params, headers);
    }

    public loadAuthState = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.loadAuthState();
        return HttpService.post(url, payload, params, headers);
    }

    public loadUserScopes = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.loadUserScopes();
        return HttpService.post(url, payload, params, headers);
    }

    public loadProfileState = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.loadProfileState();
        return HttpService.post(url, payload, params, headers);
    }

    public login = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.login();
        return HttpService.post(url, payload, params, headers);
    }

    public register = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.register();
        return HttpService.post(url, payload, params, headers);
    }

    public verifyUserEmail = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.verifyUserEmail();
        return HttpService.post(url, payload, params, headers);
    }

    public forgotPassword = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.forgotPassword();
        return HttpService.post(url, payload, params, headers);
    }

    public resetPassword = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.resetPassword();
        return HttpService.post(url, payload, params, headers);
    }

    public changePassword = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.changePassword();
        return HttpService.post(url, payload, params, headers);
    }

    public updateAccount = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updateAccount();
        return HttpService.post(url, payload, params, headers);
    }

    public logout = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.logout();
        return HttpService.post(url, payload, params, headers);
    }

    public refreshToken = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.refreshToken();
        return HttpService.post(url, payload, params, headers);
    }

    public invitePeople = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.invitePeople();
        return HttpService.post(url, payload, params, headers);
    }

    public validateIdTokenAndGenerateAccessToken = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.validateIdTokenAndGenerateAccessToken();
        return HttpService.post(url, payload, params, headers);
    }
}
